$(document).ready(function () {
     document.addEventListener("touchstart", function() {},false);
    var owl = $('.owl-slider');
    var owlPhotogalery = $('.owl-photogalery');

    validator = $("input,select,textarea").not("[type=submit]").jqBootstrapValidation();

    owl.owlCarousel({
        loop: false,
        margin: 15,
        nav: true,
        navText: ['<i class="icon icon-arrow-left2"></i>','<i class="icon icon-arrow-right2"></i>'],
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:4
            }
        }
    });

    owlPhotogalery.owlCarousel({
        loop: false,
        margin: 15,
        nav: true,
        navText: ['<i class="icon icon-arrow-left2"></i>','<i class="icon icon-arrow-right2"></i>'],
        responsive:{
            0:{
                items:2
            },
            400:{
                items:3
            },
            1000:{
                items:4
            }
        }
    });

    $('.lazy').lazy();



    $('.photo').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    $('.btn-iframe').magnificPopup({
        type: 'iframe',
        gallery: {
            enabled: false
        }
    });


    /* START rezervace */
    $('#head .nav-mobile a, #head .nav a.close').click(function() {
        if($('#head .nav').hasClass('active')){
            $('#head .nav').removeClass('active');
            $('body').removeClass('locked');
            $('#headLine').addClass('active');
        }else{
            $('#headLine').removeClass('active');
            $('#head .nav').addClass('active');
            $('body').addClass('locked');
        }
    });

    $('#head .nav-mobile a, #head .nav a.btns').click(function() {
        $('body').addClass('locked');
        $('#headLine').removeClass('active');
    });

    $(document).on("click",'.modal a.close',function(){
        $(this).closest('.modal').removeClass('active');
        $('#headLine').addClass('active');
        $('body').removeClass('locked');
    });
    $(document).on("click",'.radio',function(){
        $(this).find('input[type="radio"]').prop("checked", true);
    });



    $("input[type=text], textarea").on({ 'touchstart' : function() {
        zoomDisable();
    }});
    $("input[type=text], textarea").on({ 'touchend' : function() {
        setTimeout(zoomEnable, 500);
    }});

    function zoomDisable(){
      $('head meta[name=viewport]').remove();
      $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0" />');
    }
    function zoomEnable(){
      $('head meta[name=viewport]').remove();
      $('head').prepend('<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=1" />');
    }

    
});

function loadModalPlan(obj){
    $.get( urlWeb+'ajax/plan.php?ids='+obj.attr('data-id')+'&date='+obj.attr('data-date'), function( data ) {
        $('#detail-rozvrhu .modal-content').html(data);
        $('.modal').removeClass('active');
        $('#detail-rozvrhu').addClass('active');//.css('height',$( document ).height());
        if($(window).width() >= 770){
            $('#detail-rozvrhu').css('height',$( document ).height());
            $('#detail-rozvrhu .modal-content').css('top', $(document).scrollTop());
        }
        $('body').addClass('locked');
        $('#head .nav').removeClass('active');
    });
}
